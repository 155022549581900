@font-face{
    font-family: "BellyBeans";
    src: local("BellyBeans"),
    url("./fonts/BellyBeans/bellybeans.ttf") format("truetype");
    font-weight: auto;
}

@font-face {
    font-family: "Adigiana";
    src: local("Adigiana"),
    url("./fonts/Adigiana/adigiana.ttf") format("truetype");
    font-weight: auto;
}