.App {
  display: grid;
  gap: 30px;
}

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

*{
  padding: 0;
  margin: 0;
}

html{
  scroll-behavior: smooth;
}

body{
  background: black;
}

h1, h2, span, button{
  font-family: 'Press Start 2P', cursive;
  text-transform: uppercase;
}

span{
  color: white;
}

h1, h2{
  background: linear-gradient(90deg,magenta,cyan,pink);
  background-clip: text;
  color: #00000000;
  font-size: calc(15px + 0.5vw);
}

button{
  padding: 10px;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #78e4ff, #ff48fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000 inset;
  border-radius: 10px;
  color: white;
}

button:hover{
  cursor: pointer;
  box-shadow: none;
  color: black;
  transition: 0.5s ease;
  filter: drop-shadow(0 0 20px rgba(0, 255, 242, 0.3));
}

.Carousel{
  min-width: 200px;
  max-width: 300px;
  width: 100vw;
}

.Carousel img{
  border-radius: 20px;
}

#Header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

#HeaderRight{
  display: flex;
  align-items: center;
  gap: 10px;
}

.HeaderMarquee{
  display: flex;
  background: black;
  margin-bottom: -30px;
  border-bottom: 2px solid yellow;
  padding: 20px 0px;
}

.HeaderMarquee span{
  margin: 100px;
}

.MintMarquee{
  display: flex;
  background: linear-gradient(90deg,magenta,cyan,pink);
  margin-top: -30px;
  padding: 20px 0px;
}

.MintMarquee span{
  color: black;
  font-size: calc(15px + 1.5vw);
  margin: 60px;
}

.MintMarqueeTwo{
  display: flex;
  background: linear-gradient(90deg,magenta,cyan,pink);
  padding: 20px 0px;
  border-bottom: 3px solid yellow;
}

.MintMarqueeTwo span{
  color: black;
  font-size: calc(15px + 1.5vw);
  margin: 60px;
}

#Hero{
  display: flex;
  justify-self: center;
  gap: 30px;
  align-items: center;
  width: 60%;
}
.Slider{
  width: 300px;
}
#Hero img{
  width: 300px;
}
#InfoRight img:hover{
  transition: 0.5s ease;
  filter: drop-shadow(0 0 20px rgba(255, 249, 159, 0.3));
}

#HeroText{
  display: grid;
  gap: 20px;
  text-align: right;
}

#HeroText button{
  width: fit-content;
  justify-self: end;
}

#HeroButtons{
  display: flex;
  justify-self: end;
  gap: 10px;
}

#Info{
  display: grid;
  grid-auto-flow: column;
  justify-self: center;
  gap: 20px;
  border-top: 2px solid yellow;
  border-bottom: 2px solid yellow;
  background: black;
  width: 100%;
}
#InfoCell{
  display: grid;
  grid-auto-flow: column;
  width: 60%;
  justify-self: center;
  gap: 50px;
  padding: 50px;
}
#InfoText{
  display: grid;
  gap: 20px;
  text-align: left;
}
#InfoRight{
  display: grid;
  justify-items: center;
  gap: 10px;
}
#InfoRight img{
  width: 300px;
}
#Mint{
  display: grid;
  width: 60%;
  height: fit-content;
  justify-self: center;
  border: 3px solid yellow;
}
#Mint h1, #Mint h2{
  color: yellow;
}
#Mint span{
  color: white;
}
#Mint img{
  height: 300px;
}
.MintMarquee2{
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
  height: 100%;
}
#MintRight{
  display: grid;
  gap: 10px;
  text-align: center;
  background: black;
  padding: 20px;
  border-radius: 0 30px 30px 0;
}
#MintCell{
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 10px;
}
#ProgressBar{
  height: 30px;
  width: 80%;
  background: linear-gradient(0deg, #FFFFFF20,#FFFFFF10);
  justify-self: center;
}
#ProgressComplete{
  height: 100%;
  width: 0%;
  background: linear-gradient(45deg, cyan,pink);
  transition: 0.5s ease-in;
}
#MintButtonContainer{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr min-content;
  gap: 5px;
  width: 80%;
  justify-self: center;
  padding: 10px 0px;
}
#MintButtonContainer .Currency{
  width: 125px;
}
#Socials{
  display: flex;
  gap: 10px;
}
#Socials img:hover{
  transition: 1s ease;
  filter: drop-shadow(0 0 8px yellow);
}

#Racing{
  display: grid;
  text-align: center;
  gap: 20px;
}

#Cars{
  display: flex;
  justify-self: center;
  background: radial-gradient(gray -50%, black 60%);
}

#Car1, #Car2, #Car3{
  min-width: 150px;
  width: 20vw;
}

#Car1{
  z-index: 2;
}

#Car2{
  position: absolute;
  margin: -10px 8vw;
  transform: scale(0.8);
  filter: blur(1px);
}

#Car3{
  position: absolute;
  margin: -10px -8vw;
  transform: scale(0.8);
  filter: blur(1px);  
}

#VROOM{
  position: absolute;
  z-index: 3;
  margin: 0 14vw;
  margin-top: 70px;
}

#RacingExplanation{
  display: grid;
  gap: 5px;
  width: 60%;
  justify-self: center;
}

#RacingButtons{
  display: flex;
  justify-self: center;
  gap: 5px;
}

#Footer{
  display: flex;
  align-items: center;
  background: black;
  padding: 10px 20px;
  justify-content: space-between;
  border-top: 2px solid yellow;
}

#Footer img{
  width: 20px;
}

#Footer span{
  background: linear-gradient(90deg, cyan,pink);
  background-clip: text;
  color: #00000000;
}

#Socials img{
  width: 25px;
}

@media only screen and (max-width:430px){
  .App{
    justify-items: center;
  }
  #Header{
    display: grid;
    text-align: center;
    gap: 10px;
  }
  #HeaderRight{
    display: grid;
    justify-content: center;
  }
  #Hero{
    display: grid;
    justify-self: center;
    justify-content: center;
  }
  #HeroText{
    text-align: center;
    justify-content: center;
  }
  #HeroText button{
    justify-self: center;
  }
  #Hero img{
    width: 250px;
  }
  #HeroButtons{
    display: grid;
    justify-self: center;
  }
  #HeroButtons button{
    width: 100%;
  }
  #InfoCell{
    grid-auto-flow: row;
    justify-content: center;
  }
  #Mint{
    grid-auto-flow: row;
    width: fit-content;
    height: fit-content;
    width: 350px;
  }
  .MintMarquee2{
    margin-bottom: -5px;
    width: 350px;
  }
  #MintRight{
    border-radius: 0 0 30px 30px;
  }
  #Footer{
    display: grid;
    width: 100%;
    padding: 10px 0;
    justify-content: center;
    justify-items: center;
    text-align: center;
    gap: 10px;
  }
  #Socials{
    justify-self: center;
  }
  h1,h2{
    font-size: 16px;
  }
  span{
    font-size: small;
  }
  #MintButtonContainer{
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
  #MintButtonContainer .Currency{
    width: auto;
  }
  #VROOM{
    position: absolute;
    z-index: 3;
    margin: 0 90px;
    margin-top: 10px;
    width: 80px;
  }
  #RacingExplanation{
    width: 80%;
  }
  #RacingButtons{
    display: grid;
  }
}

@media only screen and (max-width: 1400px){
  #VROOM{
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1024px){
  #Hero{
    display: grid;
    justify-self: center;
    gap: 30px;
    align-items: center;
    width: 60%;
  }
  #HeroText{
    text-align: center;
    justify-content: center;
  }
  #HeroText button{
    justify-self: center;
  }
  #Hero img{
    width: 250px;
  }
  #HeroButtons{
    display: grid;
    justify-self: center;
  }
  #HeroButtons button{
    width: 100%;
  }
  .Carousel{
    min-width: 200px;
    max-width: 300px;
    width: 100vw;
    justify-self: center;
  }
  
  .Carousel img{
    border-radius: 20px;
  }

  #VROOM{
    width: 100px;
    margin-top: -10px;
  }

  #Footer{
    display: grid;
    justify-content: center;
    justify-items: center;
  }
}